import React from 'react';
import { Box, Heading, Text, Stack, Image } from "@chakra-ui/react";
import PlanImage from '../../images/backgrounds/plan-BR.png';
import BeanClimbing from '../../images/beans/bean-climbing.svg';
import BeanSitting from '../../images/beans/bean-sitting.svg'
import BeanPhoto from '../../images/beans/bean-photo.svg'

export default function PlanSection() {
  return <Box backgroundImage={['unset', null, `url(${PlanImage})`]} backgroundPosition="bottom right" backgroundSize={['700px auto', null, null, '1000px auto']} backgroundRepeat="no-repeat" py={150} pos="relative">
    <Image src={BeanClimbing} pos="absolute" bottom={["40px", null, null, "70px"]} right={["370px", null, null, "540px"]} display={['none', null, 'block']} width={["150px", null, null, "200px"]} />
    <Image src={BeanPhoto} pos="absolute" bottom={["120px", null, null, "170px"]} right={["150px", null, null, "220px"]} display={['none', null, 'block']} width={["110px", null, null, "150px"]} />
    <Image src={BeanSitting} pos="absolute" bottom={["300px", null, null, "430px"]} right={["60px", null, null, "90px"]} display={['none', null, 'block']} width={["110px", null, null, "150px"]} />
    <Box p={["2rem", "2rem", "4rem"]}>
      <Box w={['100%', '100%', '50%', '50%']}>
        <Stack spacing={8}>
          <Heading size="2xl">Our Plan</Heading>
          <Text>
            We are on a journey. And it’s only just begun! We have a 4-step plan:
          </Text>
          <Box data-sal="slide-right" data-sal-duration="1000">
            <Heading>Step 1</Heading>
            <Text>Build an online space to help create action and positive impact. Oh yeah, making ripples!</Text>
          </Box>
          <Box data-sal="slide-right" data-sal-duration="1000" data-sal-delay="800">
            <Heading>Step 2</Heading>
            <Text>Create a space to gather meaningful metrics (through validated data collected for good) around the impact you little change-makers are having on our beautiful planet. Woo, interactive and smart ripples!</Text>
          </Box>
          <Box data-sal="slide-right" data-sal-duration="1000" data-sal-delay="900">
            <Heading>Step 3</Heading>
            <Text>Learn from those metrics exactly what kind of impact organisations have on communities and how an individual action can have a big impact. Yeah we wanna watch those ripples grow!</Text>
          </Box>
          <Box data-sal="slide-right" data-sal-duration="1000" data-sal-delay="1000">
            <Heading>Step 4</Heading>
            <Text>Build a set of robust impact measurement tools rooted in accuracy that does not allow for impact-washing or the use of vanity metrics (yuck!) built for both organisations and individuals.</Text>
          </Box>
        </Stack>
      </Box>
    </Box>
  </Box>
}
import React from 'react';
import { Box, Heading, Text, Stack } from "@chakra-ui/react";
import BackgroundImage from '../../images/backgrounds/about-br.png';

export default function WhySection() {
  return <Box backgroundImage={`url(${BackgroundImage})`} backgroundPosition="bottom right" backgroundSize={["80%", '50%', '400px', '600px']} backgroundRepeat="no-repeat" py={150} minHeight="100vh">
    <Box p={["2rem", "2rem", "4rem"]}>
      <Box w={['100%', '100%', '70%', '50%']}>
        <Stack spacing={8}>
          <Heading size="2xl" data-sal="slide-right" data-sal-duration="1000">Why we make ripples.</Heading>
          <Text data-sal="slide-right" data-sal-duration="1000" data-sal-delay="100">
            We want to create an affordable digital platform for organisations that make an impact on our world.
          </Text>
          <Text data-sal="slide-right" data-sal-duration="1000" data-sal-delay="200">
            We want to revolutionise how these organisations communicate with their communities and how they enable them to take action.
          </Text>
          <Text data-sal="slide-right" data-sal-duration="1000" data-sal-delay="300">
            We want to elevate how we determine value online and how we measure impact and move away from vanity metrics.
          </Text>
          <Text data-sal="slide-right" data-sal-duration="1000" data-sal-delay="400">
            We want to build an online social tool with less noise and more focus. Built to support good organisations and people and not mine them for personal data for profit.
          </Text>
        </Stack>
      </Box>
    </Box>
  </Box>
}
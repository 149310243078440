import React from 'react';
import { Box, Heading, Text, Stack, Link, Image, AspectRatio, SimpleGrid } from "@chakra-ui/react";
import { Link as GatsbyLink } from 'gatsby';
import GabyImage from '../../images/profiles/Gaby.svg';
import ByronImage from '../../images/profiles/Byron.svg';
import IzzyImage from '../../images/profiles/Izzy.svg';
import HaydenImage from '../../images/profiles/Hayden.svg';

export default function WhoSection() {
  return <Box py={150} bgColor="white">
    <Box p={["2rem", "2rem", "4rem"]}>
      <Box w={['100%', '100%', '70%', '50%']}>
        <Stack spacing={8}>
          <Heading size="2xl" data-sal="slide-right" data-sal-duration="1000">Who we are</Heading>
          <Text data-sal="slide-right" data-sal-duration="1000" data-sal-delay="500">
            We are a small (but funky) Wellington-based team. We harmoniously bring together experience in social outreach, science communication, design, sky-diving, telescopes, nautical puns and web development to bring you makeripples.
          </Text>
          <Text data-sal="slide-right" data-sal-duration="1000" data-sal-delay="600">
            Be sure to <Link to="/contact" as={GatsbyLink} color="brand.primary">get in contact with us</Link>, we love a good chat.
          </Text>
        </Stack>
      </Box>
      <Box mt="4rem">
        <SimpleGrid columns={[1, 2, 4, 4]} textAlign="center" spacing="2rem">
          <Box w="100%" data-sal="slide-right" data-sal-duration="1000">
            <AspectRatio ratio={1}>
              <Image src={GabyImage} />
            </AspectRatio>
            <Heading>Gaby</Heading>
            <Text>Project Lead</Text>
          </Box>
          <Box w="100%" data-sal="slide-right" data-sal-duration="1000" data-sal-delay="250">
            <AspectRatio ratio={1}>
              <Image src={ByronImage} />
            </AspectRatio>
            <Heading>Byron</Heading>
            <Text>Lead Developer</Text>
          </Box>
          <Box w="100%" data-sal="slide-right" data-sal-duration="1000" data-sal-delay="500">
            <AspectRatio ratio={1}>
              <Image src={IzzyImage} />
            </AspectRatio>
            <Heading>Izzy</Heading>
            <Text>UX Designer</Text>
          </Box>
          <Box w="100%" data-sal="slide-right" data-sal-duration="1000" data-sal-delay="750">
            <AspectRatio ratio={1}>
              <Image src={HaydenImage} />
            </AspectRatio>
            <Heading>Hayden</Heading>
            <Text>Lead Designer</Text>
          </Box>
        </SimpleGrid>
      </Box>
    </Box>
  </Box>
}
import * as React from "react"
import Layout from '../components/layout'
import MakeRipplesSection from "../containers/sections/make-ripples-section";
import OtherPagesSection from "../containers/sections/other-pages-section";
import PlanSection from "../containers/sections/plan-section";
import WhoSection from "../containers/sections/who-section";
import WhySection from "../containers/sections/why-section";
import { Helmet } from 'react-helmet';

const About = ({ location }) => {
  return <Layout activePage={location.pathname}>
    <Helmet>
      <title>makeripples - About</title>
    </Helmet>
    <WhySection />
    <WhoSection />
    <PlanSection />
    <OtherPagesSection activePage={location.pathname} />
    <MakeRipplesSection />
  </Layout>
}

export default About;